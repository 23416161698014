import Vue from "vue";
import VueGtm from "vue-gtm";
import VueRouter from "vue-router";
import $eventHub from "@/components/eventHub";
import { pinia } from "@/main";
import { useAuthStore } from "@/stores/authStore";
import { useUserStore } from "@/stores/userStore";
import { useNavbarStore } from "@/stores/navbarStore";
import { useSearchStore } from "@/stores/searchStore";
import { useCoreStore } from "@/stores/coreStore";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Splash",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "general" */ "@/views/AppSplash.vue"
      ),
  },
  //404
  {
    path: "/404",
    name: "NotFound",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "general" */ "@/views/notFound/NotFoundHome.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "*",
    redirect: "/404",
    productionAvailable: true,
  },
  // AUTH
  {
    path: "/login",
    name: "Login",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "general" */ "@/views/auth/AppLogin.vue"
      ),
  },
  {
    path: "/login-sso",
    name: "LoginSSO",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "general" */ "@/views/auth/AppLogin.vue"
      ),
  },
  {
    path: "/auth/callback",
    name: "AuthCallback",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "general" */ "@/views/AppSplash.vue"
      ),
  },
  {
    path: "/validate-account",
    name: "ValidateAccount",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "general" */ "@/views/auth/ValidateAccount"
      ),
  },
  {
    path: "/set-password",
    name: "SetPassword",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "general" */ "@/views/auth/SetPassword"
      ),
  },
  //ONBOARDING
  {
    path: "/onboard",
    name: "OnBoard",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "general" */ "@/views/OnBoard"
      ),
  },
  //CONTEXTUAL-HELPERS
  {
    path: "/contextual-helpers",
    name: "ContextualHelpers",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true*/ /* webpackChunkName: "contextual-helpers" */ "@/views/contextual-helpers/ContextualHelpersHome.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/contextual-helpers/routes",
    name: "ContextualHelpersRoutes",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true*/ /* webpackChunkName: "contextual-helpers" */ "@/views/contextual-helpers/ContextualRoutes"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  //PROFILE
  {
    path: "/profile",
    name: "Profile",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "profiling" */ "@/views/profile/ProfileHome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/profile/edit",
    name: "ProfileEdit",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "profiling" */ "@/views/profile/ProfileEdit"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/questions",
    name: "Questions",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "profiling" */ "@/views/profile/ProfileQuestions.vue"
      ),
  },
  {
    path: "/result",
    name: "Result",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "profiling" */ "@/views/profile/ProfileResult.vue"
      ),
  },
  {
    path: "/auth/changePassword",
    name: "ChangePassword",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "profiling" */ "@/views/auth/ChangePassword.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/profile/personalData",
    name: "PersonalData",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "profiling" */ "@/views/profile/personalData"
      ),
    meta: {
      layout: "MainLayout",
    },
  },

  //HOME
  {
    path: "/home",
    name: "Home",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "general" */ "@/views/AppHome.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
    beforeEnter: (to, from, next) => {
      if (from.path === "/" && caches) {
        caches
          .keys()
          .then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
              caches.delete(cacheName).then((r) => console.log(r));
            });
          })
          .then(() => {
            next();
          });
      } else {
        next();
      }
    },
  },
  //SUPPORT
  {
    path: "/support/suggestionsMailbox",
    name: "SuggestionsMailbox",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "support" */ "@/views/support/SuggestionsHome.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  //BENEFITS AND AGREEMENTS
  {
    path: "/benefits",
    name: "BenefitsNew",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "benefitsAndAgreements" */ "@/views/benefits/BenefitsHome.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/benefits/:id",
    name: "BenefitDetail",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "benefitsAndAgreements" */ "@/views/benefits/BenefitDetail.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/agreements",
    name: "AgreementsNew",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "benefitsAndAgreements" */ "@/views/agreements/AgreementsHome.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/agreements/:id",
    name: "AgreementDetail",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "benefitsAndAgreements" */ "@/views/agreements/AgreementDetail"
      ),
    meta: {
      layout: "MainLayout",
    },
  },

  //DIVERSITY AND INCLUSION
  {
    path: "/diversity",
    name: "Diversity",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "diversity" */ "@/views/diversity/DiversityHome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/diversity/OurPolitics",
    name: "Our Politics",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "diversity" */ "@/views/diversity/OurPolitics"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/diversity/ambassadorsDI",
    name: "Ambassadors D&I",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "diversity" */ "@/views/diversity/AmbassadorsDI.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/diversity/helpLinesDI",
    name: "Help Lines D&I",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "diversity" */ "@/views/diversity/HelpLinesDI.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/diversity/champions",
    name: "Champions",
    productionAvailable: false,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "diversity" */ "@/views/diversity/ChampionsHome.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },

  //SHOW ME THE MONEY AND VARIABLE COMPENSATION
  {
    path: "/showMeTheMoney",
    name: "ShowMeTheMoney",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "showMeTheMoney" */ "@/views/showMeTheMoney/Home"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/benefits",
    name: "Beneficios",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "showMeTheMoney" */ "@/views/showMeTheMoney/UserBenefits"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/fixedCalculator/:bonusType",
    name: "CalculadoraCompensacionFija",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "showMeTheMoney" */ "@/views/showMeTheMoney/StiBonus"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/extra-hours",
    name: "ExtraHoursHome",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "showMeTheMoney" */ "@/views/showMeTheMoney/extra-hours/Home"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/extra-hours/calculator",
    name: "CalculatorExtraHours",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "showMeTheMoney" */ "@/views/showMeTheMoney/extra-hours/CalculatorExtraHours"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/extra-hours/how-calculate",
    name: "HowCalculate",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "showMeTheMoney" */ "@/views/showMeTheMoney/extra-hours/HowCalculate"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/remuneracion-variable",
    name: "VariableCompensation",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "compensation" */ "@/views/showMeTheMoney/variable-compensation/VariableCompensation"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/remuneracion-variable/historial",
    name: "RemuneracionVariableHistorial",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "compensation" */ "@/views/showMeTheMoney/variable-compensation/HistoryRemuneration"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/variable-calculator",
    name: "Calculadora Remuneracion Variable",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "compensation" */ "@/views/showMeTheMoney/variable-compensation/VariableCompensationCalculator"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/remuneracion-pagos",
    name: "VariableCompensationPayments",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "compensation" */ "@/views/showMeTheMoney/variable-compensation/VariableCompensationPayments"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/remuneracion-rankings",
    name: "Ranking Remuneracion Variable",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "compensation" */ "@/views/showMeTheMoney/variable-compensation/VariableCompensationRanking"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/remuneracion-reportes",
    name: "Soporte Remuneracion Variable",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "compensation" */ "@/views/showMeTheMoney/variable-compensation/VariableCompensationTickets"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/showMeTheMoney/learn-more",
    name: "Aprende Más",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "compensation" */ "@/views/showMeTheMoney/LearnMore"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  /*  {
    path: "/showMeTheMoney/request-form/:id",
    name: "Solicitar certificados",
    productionAvailable: true,
    component: () =>
      import(
        /!* webpackPrefetch: true *!/ /!* webpackChunkName: "compensation" *!/ "@/views/showMeTheMoney/RequestForm"
      ),
    meta: {
      layout: "MainLayout"
    }
  },*/
  {
    path: "/showMeTheMoney/certificates",
    name: "Solicitar Certificados",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "compensation" */ "@/views/showMeTheMoney/Certificates"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  // E-COMMERCE
  {
    path: "/e-commerce",
    name: "ECommerce",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ecommerce" */ "@/views/eCommerce/EcommerceHome.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/e-commerce/shoppingHistory",
    name: "ShoppingHistory",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ecommerce" */
        "@/views/eCommerce/ShoppingHistory"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/e-commerce/product/:id",
    name: "Product",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ecommerce" */ "@/views/eCommerce/ProductDetail.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/e-commerce/monthProduct",
    name: "MonthProduct",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ecommerce" */ "@/views/eCommerce/MonthProduct"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/e-commerce/monthProductShoppingCart",
    name: "MonthProductShoppingCart",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ecommerce" */ "@/views/eCommerce/MonthProductShoppingCart"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/e-commerce/couponDetail",
    name: "CouponDetail",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ecommerce" */ "@/views/eCommerce/CouponDetail.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/e-commerce/view-all/:cat",
    name: "ViewAll",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ecommerce" */ "@/views/eCommerce/ViewAll"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/e-commerce/cart/pending",
    name: "CartPending",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ecommerce" */ "@/views/eCommerce/CartPending"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/e-commerce/cart/history",
    name: "CartHistory",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ecommerce" */ "@/views/eCommerce/CartHistory"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/e-commerce/thanks",
    name: "CartThankYou",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ecommerce" */ "@/views/eCommerce/CartThankYou"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/e-commerce/orderStatus",
    name: "OrderStatus",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ecommerce" */ "@/views/eCommerce/OrderStatus"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  //TRIVIA
  {
    path: "/competitions",
    name: "Competitions",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "competitions" */ "@/views/competition/Competitions"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/competition/competition/:id",
    name: "Competition",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "competitions" */ "@/views/competition/CompetitionView.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/competition/participate",
    name: "CompetitionParticipate",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "competitions" */ "@/views/competition/Participate"
      ),
  },
  {
    path: "/competition/question/:id",
    name: "CompetitionQuestion",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "competitions" */ "@/views/competition/Question"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/competition/social-form/:id",
    name: "CompetitionSocialForm",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "competitions" */ "@/views/competition/SocialForm"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/competition/participants/:id",
    name: "CompetitionParticipants",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "competitions" */ "@/views/competition/Participants"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/competition/congrats",
    name: "CompetitionCongrats",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "competitions" */ "@/views/competition/Congrats"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/competition/ranking/:id",
    name: "CompetitionRanking",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "competitions" */ "@/views/competition/Ranking"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  // RESERVATIONS
  {
    path: "/reservations",
    name: "Reservations",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "reservations" */ "@/views/reservations/ReservationsHome.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/reservations/space/:id",
    name: "ReservationsSpace",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "reservations" */ "@/views/reservations/ReservationsSpace.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/reservations/space/:id/book",
    name: "SpaceBook",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "reservations" */ "@/views/reservations/ReservationsBookSpace.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/reservations/space/:id/book127",
    name: "Book127",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "reservations" */ "@/views/reservations/ReservationsBookSpace127.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/reservations/space/:id/book/lot",
    name: "CongratulationsLot",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "reservations" */ "@/views/reservations/ReservationsCongratulationsLot.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/reservations/space/:id/book/thanks",
    name: "CongratulationsFinal",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "reservations" */ "@/views/reservations/ReservationsCongratulationsFinal.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/reservations/space/:id/book127/thanks",
    name: "CongratulationsFinal127",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "reservations" */ "@/views/reservations/ReservationsCongratulationsFinal127.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/reservations/space/:id/book/payment",
    name: "BookPayment",
    productionAvailable: true,
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "reservations" */ "@/views/reservations/ReservationsBookPayment.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/reservations/bookingHistory",
    name: "BookingHistory",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "reservations" */ "@/views/reservations/ReservationsBookingHistory.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/reservations/space/:id/bookRating",
    name: "BookRating",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "reservations" */ "@/views/reservations/ReservationsBookRating.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/reservations/space/:id/bookPending",
    name: "BookPending",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "reservations" */ "@/views/reservations/ReservationsBookPending.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },

  // ARTICLE CATEGORIES
  {
    path: "/articles",
    name: "Articles",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "articles" */ "@/views/articles/ArticlesHome.vue"
      ),
    props: true,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/articles/:id",
    name: "Article",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "articles" */ "@/views/articles/ArticleDetail.vue"
      ),
    props: true,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/categories-article/:id",
    name: "Article Categories",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "articles" */ "@/views/article-categories/ArticleCategories"
      ),
    props: true,
    meta: {
      layout: "MainLayout",
    },
  },

  //WORLD BRANDS
  {
    path: "/brands",
    name: "WorldBrands",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "brands" */ "@/views/brands/WorldBrands"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/brand/:id",
    name: "Brand",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "brands" */ "@/views/brands/Brand"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  //SOMOS EMBAJADORES
  {
    path: "/ambassadors/talent",
    name: "Talent",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors" */ "@/views/ambassadors/Talent"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/platforms",
    name: "Platforms",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors" */ "@/views/ambassadors/Platforms"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/people/cv",
    name: "CurriculumVitae",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors-people" */ "@/views/ambassadors/people/CurriculumVitae"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/engagement",
    name: "Engagement",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors" */ "@/views/ambassadors/Engagement"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/indicators/compareleaders",
    name: "CompareLeaders",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors" */ "@/views/ambassadors/indicators/CompareLeaders"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/peoplecycle",
    name: "PeopleCycle",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors-peopleCycle" */ "@/views/ambassadors/PeopleCycle"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/peopleCycle/targetbonus",
    name: "TargetBonus",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors-peopleCycle" */ "@/views/ambassadors/peopleCycle/TargetBonus"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/peopleCycle/capabilities",
    name: "Capabilities",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors-peopleCycle" */ "@/views/ambassadors/peopleCycle/Capabilities"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/peopleCycle/pdp",
    name: "PDP",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors-peopleCycle" */ "@/views/ambassadors/peopleCycle/PDP"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/peopleCycle/opr",
    name: "OPR",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors-peopleCycle" */ "@/views/ambassadors/peopleCycle/OPR"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/peopleCycle/updateprofile",
    name: "UpdateProfile",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors-peopleCycle" */ "@/views/ambassadors/peopleCycle/UpdateProfile"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/people/cv/view",
    name: "ViewCv",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "curriculumVitae" */ "@/views/ambassadors/people/ViewCv"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/people/opportunity/:id",
    name: "Opportunities",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors-people" */ "@/views/ambassadors/people/Opportunities.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/people/movements",
    name: "PeopleMovements",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors-people" */ "@/views/ambassadors/people/Movements"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/people/request/:id",
    name: "RequestsAmbassadors",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "requests" */ "@/views/ambassadors/people/Requests"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ambassadors/ranking-people",
    name: "RankingPeople",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ambassadors-people" */ "@/views/ambassadors/people/RankingPeople"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  //Contactos
  {
    path: "/my-contacts",
    name: "MyContacts",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "contacts" */ "@/views/contacts/MyContacts"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  //Gamification
  {
    path: "/gamification",
    name: "",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/GamificationHome.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
    children: [
      {
        path: "",
        name: "gamificationProfile",
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/GamificationProfile.vue"
          ),
        meta: {
          layout: "MainLayout",
        },
      },
      {
        path: "malts",
        name: "gamificationMalts",
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/GamiMalts"
          ),
        meta: {
          layout: "MainLayout",
        },
        props: true,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem("tapitSession")) {
            next();
          } else {
            next({ name: "gamificationMaltsWelcome" });
          }
        },
      },
      {
        path: "maltsWelcome",
        name: "gamificationMaltsWelcome",
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/GamiMaltsWelcome"
          ),
        meta: {
          layout: "MainLayout",
        },
      },
      {
        path: "malts/history",
        name: "gamificationMaltsHistory",
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/GamiMaltsHistory"
          ),
        meta: {
          layout: "MainLayout",
        },
      },
      {
        path: "missions",
        name: "gamificationMissions",
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/GamiMissions"
          ),
        meta: {
          layout: "MainLayout",
        },
      },
      {
        path: "collections",
        name: "gamificationCollections",
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/GamiCollections"
          ),
        meta: {
          layout: "MainLayout",
        },
      },
      {
        path: "rewards",
        name: "gamificationRewards",
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/GamiRewards"
          ),
        meta: {
          layout: "MainLayout",
        },
      },
      {
        path: "form",
        name: "gamificationForm",
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/GamiForm"
          ),
        meta: {
          layout: "MainLayout",
        },
        props: true,
      },
      {
        path: "rewards/:id",
        name: "gamificationRedeem",
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/Redeem"
          ),
        meta: {
          layout: "MainLayout",
        },
      },
    ],
  },
  {
    path: "/gamification/avatar",
    name: "gamificationAvatar",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/Avatar"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/gamification/rewardsHistory",
    name: "gamificationRewardsHistory",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/RewardsHistory"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/gamification/redeemHistory/:id",
    name: "gamificationRedeemHistory",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/RedeemHistory"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/gamification/welcome",
    name: "gamificationWelcome",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/Welcome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/gamification/first-time",
    name: "gamificationFirstTime",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/FirstTime"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/gamification/spirit",
    name: "gamificationSpirit",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/Spirit"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/gamification/how-it-work",
    name: "gamificationHowItWork",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "gamification" */ "@/views/gamification/HowItWork"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  // INDICATORS
  {
    path: "/indicators/psi",
    name: "indicatorsPsi",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "indicators" */ "@/views/indicators/Psi"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/indicators/psi/detail",
    name: "indicatorsPsiDetail",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "indicators" */ "@/views/indicators/PsiDetail"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/indicators/psi/compare",
    name: "indicatorsPsiCompare",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "indicators" */ "@/views/indicators/PsiCompare"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  // Go Flex
  {
    path: "/goflex",
    name: "goFlex",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "goflex" */ "@/views/goFlex/goFlexHome.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/goflex/:id/detail",
    name: "goFlexDetail",
    productionAvailable: true,
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "goflex" */ "@/views/goFlex/goFlexDetail"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/goflex/requestsHistory",
    name: "goFlexRequestsHistory",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "goflex" */ "@/views/goFlex/goFlexRequestsHistory.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/goflex/lineManagerTeamRequests",
    name: "goFlexLineManagerTeamRequests",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "goflex" */ "@/views/goFlex/goFlexLineManagerTeamRequests.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  //People Tour
  {
    path: "/peopleTour",
    name: "peopleTour",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "peopleTour" */ "@/views/peopleTour/PeopleTourHome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/peopleTour/:id/detail",
    name: "peopleTourDetail",
    productionAvailable: true,
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "peopleTour" */ "@/views/peopleTour/PeopleTourDetail"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/peopleTour/activity/:id/detail",
    name: "peopleTourActivityDetail",
    productionAvailable: true,
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "peopleTour" */ "@/views/peopleTour/PeopleTourActivityDetail"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/peopleTour/rewards",
    name: "peopleTourRewards",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "peopleTour" */ "@/views/peopleTour/PeopleTourRewards.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/peopleTour/rewards/:id",
    name: "peopleTourRewardsProduct",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "peopleTour" */ "@/views/peopleTour/PeopleTourRewardsProduct.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  // Education
  {
    path: "/education",
    name: "education",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "education" */ "@/views/education/EducationHome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/customizedBenefits",
    name: "customizedBenefits",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "customizedBenefits" */ "@/views/benefits/CustomizedBenefitsHome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  // Calendar
  {
    path: "/calendar",
    name: "calendar",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "calendar" */ "@/views/calendar/CalendarHome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  // Time Benefits
  {
    path: "/timeBenefits",
    name: "timeBenefits",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "timeBenefits" */ "@/views/timeBenefits/TimeBenefitsHome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/timeBenefits/:id/detail",
    name: "timeBenefitsDetail",
    productionAvailable: true,
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "timeBenefits" */ "@/views/timeBenefits/TimeBenefitsDetail.vue"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/timeBenefits/:id/voucher",
    name: "timeBenefitsUploadVoucher",
    productionAvailable: true,
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "timeBenefits" */ "@/views/timeBenefits/TimeBenefitsUploadVoucher"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/timeBenefits/history",
    name: "timeBenefitsHistory",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "timeBenefits" */ "@/views/timeBenefits/TimeBenefitsHistory"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/contests",
    name: "Contests",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "contests" */ "@/views/contests/ContestsHome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/contests/:id",
    name: "ContestDetail",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "contests" */ "@/views/contests/ContestDetail"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/contests/:id/inside",
    name: "ContestInside",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "contests" */ "@/views/contests/ContestInside"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/contests/:id/finish",
    name: "ContestFinish",
    productionAvailable: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "contests" */ "@/views/contests/ContestFinish"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  // Events
  {
    path: "/events",
    name: "Events",
    productionAvailable: false,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "events" */ "@/views/events/EventsHome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/events/:id",
    name: "EventDetail",
    productionAvailable: false,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "events" */ "@/views/events/EventDetail"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  // Talent Fest
  {
    path: "/talentFest",
    name: "TalentFest",
    productionAvailable: false,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "talentFest" */ "@/views/talentFest/TalentFestHome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/vamosPaLaCalle",
    name: "VamosPaLaCalle",
    productionAvailable: false,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "vamosPaLaCalle" */ "@/views/vamosPaLaCalle/VamosPaLaCalleHome"
      ),
    meta: {
      layout: "MainLayout",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ x: 0, y: 0, behavior: "smooth" }),
  routes:
    process.env.VUE_APP_ENVIRONMENT === "production"
      ? routes.filter((route) => route.productionAvailable)
      : routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore(pinia);
  const navbarStore = useNavbarStore(pinia);
  const searchStore = useSearchStore(pinia);
  const coreStore = useCoreStore(pinia);
  $eventHub.$emit("asyncComponentLoading", to);

  navbarStore.closeAllModals();
  searchStore.hideSearch();
  navbarStore.hideSupportButton();

  const publicRoutes = ["Login", "ValidateAccount", "AuthCallback", "LoginSSO"];
  const authRequired = !publicRoutes.includes(to.name);

  if (authRequired) {
    await coreStore.actGetConfigurations();
    const token = sessionStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (token && user && user !== "undefined") {
      try {
        await authStore.actValidateToken();
      } catch (error) {
        console.error(error);
        await authStore.actAuthLogout();
        if (to.fullPath !== "/")
          sessionStorage.setItem("redirect", to.fullPath);
        return next({ name: "Login" });
      }

      if (!authStore.tokenIsValid) {
        await authStore.actAuthLogout();
        if (to.fullPath !== "/")
          sessionStorage.setItem("redirect", to.fullPath);
        return next({ name: "Login" });
      }
    } else {
      await authStore.actAuthLogout();
      if (to.fullPath !== "/") sessionStorage.setItem("redirect", to.fullPath);
      return next({ name: "Login" });
    }
  }

  next();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeResolve((to, from, next) => {
  const userStore = useUserStore(pinia);
  const navbarStore = useNavbarStore(pinia);

  navbarStore.showSupportButton();
  $eventHub.$emit("asyncComponentLoaded");

  if (to.name === "gamificationMaltsWelcome") {
    const feature = userStore.user?.tapit_feature;

    if (feature === 1) return next();
    if (feature === 0)
      return next(from.path === "/" ? { path: "/home" } : false);
  }

  next();
});

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
    gtm_auth: process.env.VUE_APP_GTM_AUTH,
    gtm_preview: "env-1",
    gtm_cookies_win: "x",
  },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether display console logs debugs (optional)
  loadScript: true, // Whether to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: [''], // If router, you can exclude some routes name (case-insensitive) (optional)
  trackOnNextTick: false, // Whether call trackView in Vue.nextTick
});

export default router;
